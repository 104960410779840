/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 */
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js'; // Included with Angular.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Polyfill for Angular's localization support.
 * This resolves the polyfill warning you encountered.
 */

import '@angular/localize/init';
import 'zone.js';

/**
 * Polyfills for Node.js core modules used by dependencies (e.g., @zoom/appssdk).
 * These imports are optional because your custom Webpack configuration handles polyfills via ProvidePlugin.
 * However, including them here can help ensure they are loaded correctly.
 */
// Uncomment the following lines if you encounter issues related to Buffer or process.
import 'buffer';
import 'process/browser';

/**
 * Optional: Import additional polyfills as needed.
 * For example, if you encounter issues related to the 'vm' module, ensure that 'vm-browserify' is polyfilled via Webpack.
 */

// import 'vm-browserify'; // Not typically needed here since Webpack handles it.

/***************************************************************************************************
 * CUSTOM POLYFILLS
 */

// Add any other custom polyfills below this line.
